/* ==========================================================================
   #DIVIDER
   ========================================================================== */

.c-divider {
	display: block;
	width: 100%;
	height: 1px;
	background-color: var(--color-basic-500);
	margin: var(--spacing-unit-large) 0;
}
