.mat-paginator {
	background-color: transparent;

	.mat-paginator-page-size-label {
		color: var(--color-basic-1000);
	}

	.mat-select {
		border: 1px solid var(--color-basic-500);
		border-radius: 5px;
		padding: 0.4rem 0.5rem;
		margin-top: -0.3rem;

		width: 4.8rem;
		min-width: 4.8rem;
		max-width: 4.8rem;

		height: 1.9rem;
		min-height: 1.9rem;
		max-height: 1.9rem;

		.mat-select-arrow-wrapper {
			background-image: url("../../../assets/icons/arrow-down.svg");
		}

		.mat-select-arrow {
			opacity: 0;
		}

		.mat-select-min-line {
			color: var(--color-basic-1000);
		}
	}

	.mat-paginator-icon {
		border: 1px solid var(--color-basic-500);
		border-radius: 5px;
		height: 1.8rem;
		fill: var(--color-basic-1000);
		stroke-width: 1.5px;
	}

	.mat-paginator-range-label {
		margin-right: -3.9rem;
		margin-left: 4rem;
		border: 1px solid var(--color-basic-500);
		border-left: none;
		border-right: none;
		padding: 0.39rem 0.2rem;
		margin-top: 0.25rem;
		color: var(--color-basic-1000);

		width: 6rem;
		min-width: 6rem;
		max-width: 6rem;

		height: 1.9rem;
		min-height: 1.9rem;
		max-height: 1.9rem;

		text-align: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.mat-paginator-navigation-previous {
		.mat-paginator-icon {
			margin-left: -8.5rem !important;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;

			fill: transparent;
			stroke: var(--color-basic-1000);
			height: 30px;
			max-height: 30px;
			min-height: 30px;
			width: 28px;
			max-width: 28px;
			min-width: 28px;
			padding: 0.2rem;
			path {
				d: path("M15 19.9201L8.47997 13.4001C7.70997 12.6301 7.70997 11.3701 8.47997 10.6001L15 4.08008");
			}
		}
	}

	.mat-paginator-navigation-next {
		.mat-paginator-icon {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;

			fill: transparent;
			stroke: var(--color-basic-1000);
			height: 30px;
			max-height: 30px;
			min-height: 30px;
			width: 28px;
			max-width: 28px;
			min-width: 28px;
			padding: 0.2rem;
			path {
				d: path("M8.91003 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91003 4.08008");
			}
		}
	}

	.mat-form-field-underline {
		display: none;
	}

	.mat-form-field {
		background: unset !important;
		border-radius: unset !important;
		border-style: unset !important;
		border-width: unset !important;
		border-color: unset !important;
	}

	.mat-form-field:hover {
		background: unset !important;
		border-radius: unset !important;
		border-style: unset !important;
		border-width: unset !important;
		border-color: unset !important;
	}

	.mat-form-field:focus {
		background: unset !important;
		border-radius: unset !important;
		border-style: unset !important;
		border-width: unset !important;
		border-color: unset !important;
	}

	.mat-form-field:focus-within {
		background: unset !important;
		border-radius: unset !important;
		border-style: unset !important;
		border-width: unset !important;
		border-color: unset !important;
	}
}
