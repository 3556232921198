/* You can add global styles to this file, and also import other style files */

@use "@angular/material" as mat;

$custom-typography: mat.define-typography-config(
	$font-family: "General Sans"
);
@include mat.core($custom-typography);

@import "app/styles/main.scss";
