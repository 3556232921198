/* ==========================================================================
   #ANGULAR MATERIALS: NAV LIST
   ========================================================================== */

// To override the mat-expansion-panel custom styles
mat-nav-list {
	mat-accordion {
		& + mat-accordion {
			display: unset;
			margin-top: unset;
		}
	}

	mat-expansion-panel {
		& + mat-expansion-panel {
			margin-top: 0 !important;
			border-radius: 0px !important;
		}
	}

	mat-expansion-panel-header {
		padding: 0 24px !important;
		background-color: transparent;

		&.mat-expanded {
			background-color: transparent;

			&:focus,
			&:hover {
				background: transparent !important;
			}
		}

		.mat-expansion-indicator:after {
			color: var(--color-basic-100);
		}
	}

	mat-expansion-panel-header-title {
		justify-content: unset;
	}
}
