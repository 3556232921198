/* ==========================================================================
   #ANGULAR MATERIALS: SNACK BAR COMPONENT
   ========================================================================== */

snack-bar-container {
	&.danger {
		background-color: var(--color-basic-1100);
		color: var(--color-basic-100);
	}

	&.info {
		background-color: var(--color-basic-1100);
		color: var(--color-basic-100);
	}

	&.success {
		background-color: var(--color-basic-1100);
		color: var(--color-basic-100);
	}

	&.warning {
		background-color: var(--color-basic-1100);
		color: var(--color-basic-100);
	}
}
