/* ==========================================================================
   #ANGULAR MATERIALS: PROGRESS SPINNER
   ========================================================================== */

mat-spinner {
	position: absolute !important;
	left: 50%;
	top: 50%;
	transform: translate(-50%);
	z-index: 10;
}
