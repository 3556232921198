@mixin flex-container {
	display: flex !important;
	box-sizing: border-box !important;
}

@mixin flex-direction($direction) {
	flex-direction: $direction;
}

@mixin justify-content($alignment) {
	justify-content: $alignment;
}

@mixin align-items($alignment) {
	align-items: $alignment;
}

@mixin alignment($alignment, $content) {
	align-items: $alignment !important;
	justify-content: $content;
}

@mixin flex-grow($value) {
	flex-grow: $value;
}

@mixin flex-shrink($value) {
	flex-shrink: $value;
}

@mixin flex-item($flex) {
	flex: $flex;
}

@mixin flex-px($grow, $shrink, $basis, $max, $min) {
	flex-grow: $grow;
	flex-shrink: $shrink;
	flex-basis: $basis;
	max-width: $max;
	min-width: $min;
}

@mixin flex($grow, $shrink, $basis, $width) {
	flex-grow: $grow;
	flex-shrink: $shrink;
	flex-basis: $basis;
	max-width: $width;
}

@mixin gap($gap) {
	gap: $gap;
}

@mixin wrap($wrap) {
	flex-wrap: $wrap;
}

@mixin gap-grid($value) {
	margin: 0px calc(#{$value} * -1) calc(#{$value} * -1) 0px;
}

@mixin align-end-center($alignment, $alignment2) {
	place-content: $alignment $alignment2;
	flex-direction: row;
	flex: 1 1 100%;
}

/** CLASSES **/
.flex {
	@include flex-container();

	&-row {
		@include flex-direction(row);
	}

	&-column {
		@include flex-direction(column);
	}

	&-align-center-start {
		@include alignment(center, flex-start);
	}

	&-align-center-center {
		@include alignment(center, center);
	}

	/** fxLayoutAlign: end none **/

	&-align-center {
		@include align-items(center);
	}

	&-align-stretch-end {
		@include alignment(stretch, flex-end);
	}

	&-align-center-end {
		@include alignment(center, flex-end);
	}

	&-align-end-center {
		@include align-end-center(center, flex-end);
		@include align-items(center);
		@include flex-container();
	}

	&-align-space-between-end {
		@include alignment(space-between, flex-end);
	}

	&-align-center-space-between {
		@include alignment(center, space-between);
	}

	&-align-stretch-center {
		@include alignment(stretch, center);
	}

	/** fxLayoutAlign: space-between **/
	&-align-stretch-space-between {
		@include alignment(stretch, space-between);
	}

	/** fxLayoutAlign: space-around center **/
	&-align-center-space-around {
		@include alignment(center, space-around);
	}

	/** fxLayoutAlign: space-evenly center **/
	&-align-center-space-evenly {
		@include alignment(center, space-evenly);
	}

	&-align-start {
		@include alignment(flex-start, flex-start);
	}

	&-align-end {
		@include alignment(flex-end, flex-end);
	}

	&-align-start-end {
		@include alignment(flex-start, flex-end);
	}

	&-1 {
		@include flex(1, 1, 100%, var(--max-width));
	}

	&-px {
		@include flex-px(1, 1, var(--basis), var(--max-px-width), var(--min-px-width));
	}

	&-gap {
		@include gap(var(--gap));
	}
	&-gap-grid {
		@include gap-grid(var(--gap-grid));
	}

	&-wrap {
		@include wrap(var(--wrap));
	}

	&-item {
		@include flex-item(var(--item));
	}
}
