/* ==========================================================================
   #PREFIX-VARIABLES
   ========================================================================== */

$prefixes: ("webkit", "moz", "ms", "o");

@mixin prefix($property, $value) {
	@each $prefix in $prefixes {
		#{'-' + $prefix + '-' + $property}: $value;
	}

	// Output standard non-prefixed declaration
	#{$property}: $value;
}

@mixin transition($x...) {
	-webkit-transition: $x;
	-moz-transition: $x;
	-ms-transition: $x;
	-o-transition: $x;
	transition: $x;
}
