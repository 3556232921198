.pulsate {
	animation: pulsate 1.5s ease-in-out infinite alternate;
	opacity: 0.5;
	font-size: 25px;
	font-weight: 600;
	color: var(--color-label-input);
	display: flex;
	flex: 1;
	justify-content: center;
	padding-top: 3rem;
}

@keyframes pulsate {
	0% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}
