mat-tab-group {
	.mat-tab-label {
		opacity: unset !important;
	}

	.mat-ink-bar {
		background-color: var(--color-primary-1000) !important;
	}

	.mat-tab-label-active {
		color: var(--color-primary-1000);
		font-weight: 600;
	}
}
