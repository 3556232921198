mat-dialog-container {
	padding: 0 !important;
	border-radius: 12px !important;
}

/**
 * Force a MatDialog to display on top.
 * BreadCrumbs overlay the dialog if the z-index is not set higher.
 *
 * @see BreadCrumbsComponent
 */
.cdk-overlay-container {
	position: fixed;
	z-index: 99999;
}
