.skeleton {
	animation: skeleton-loading 2s linear infinite alternate;
	pointer-events: none !important;
}

// make all child elements inside the skeleton container transparent
// but take the same amount of space the would if they were not transparent
// block all pointer events on the child elements
.skeleton * {
	opacity: 0;
	background-color: white !important;
	pointer-events: none !important;
}

// reveal fades in the elements
.skeleton-reveal {
	animation: skeleton-reveal 1s linear forwards;
}

@keyframes skeleton-loading {
	0% {
		background-color: hsl(200, 20%, 80%);
	}
	100% {
		background-color: hsl(200, 20%, 95%);
	}
}

@keyframes skeleton-reveal {
	0% {
		background-color: hsl(200, 20%, 95%);
		opacity: 0;
	}

	100% {
		// no styles applied so it transitions back to original element styles.
	}
}
