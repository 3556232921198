/* ==========================================================================
   #ANGULAR MATERIALS: Radio group
   ========================================================================== */

mat-radio-group {
	display: block;

	mat-radio-button {
		&:not(:last-child) {
			margin-right: 24px;
		}
	}
}
