.cancel-btn {
	width: 15%;
	color: var(--color-enabled-btn-secondary) !important;
	border-color: var(--color-enabled-btn-secondary) !important;
}

.cancel-btn[disabled] {
	width: 15%;
	color: var(--color-disabled-btn) !important;
	border-color: var(--color-disabled-btn) !important;
}

.submit-btn {
	width: 15%;
	background-color: var(--color-enabled-btn-primary);
	color: #fff;
}

.submit-btn[disabled] {
	width: 15%;
	background-color: var(--color-disabled-btn) !important;
	color: #fff !important;
}

.btn-container {
	display: flex;
	justify-content: flex-end;
	padding-top: 4em;
}

.load-more-btn {
	font-size: var(--font-size-large);
	font-weight: 500;
	font-family: $font-family;
	line-height: 16px;
	background-color: transparent;
	border: transparent;
	color: var(--color-disabled-secondary);
	cursor: pointer;

	&-container {
		margin-top: 90px;
	}
}
