/* ==========================================================================
   #SPACING-VARIABLES
   ========================================================================== */

:root {
	// The basis of the spacing metric.
	--baseline: 4px;

	// The grid lines that our variants of spacing units should cover.
	// Each value should be an unitless integer.
	--spacing-unit-factor-tiny: 1;
	--spacing-unit-factor-small: 2;
	--spacing-unit-factor-medium: 3;
	--spacing-unit-factor: 4;
	--spacing-unit-factor-middle-large: 6;
	--spacing-unit-factor-large: 8;
	--spacing-unit-factor-huge: 16;

	// The spacing values are determined based on the global baseline
	// grid of your project. That is the value to modify as appropriate.
	// We don't recommend modifying these variables below, it may break
	// your vertical rhythm.
	--spacing-unit-tiny: calc(var(--baseline) * var(--spacing-unit-factor-tiny)); // 4px
	--spacing-unit-small: calc(var(--baseline) * var(--spacing-unit-factor-small)); // 8px
	--spacing-unit-middle-small: calc(var(--baseline) * var(--spacing-unit-factor-medium)); // 12px
	--spacing-unit: calc(var(--baseline) * var(--spacing-unit-factor)); // 16px
	--spacing-unit-middle-large: calc(var(--baseline) * var(--spacing-unit-factor-middle-large)); // 24px
	--spacing-unit-large: calc(var(--baseline) * var(--spacing-unit-factor-large)); // 32px
	--spacing-unit-huge: calc(var(--baseline) * var(--spacing-unit-factor-huge)); // 64px

	--page-padding-large-screens: 6.5rem;
	--page-padding-small-screens: 3.1rem;
}
