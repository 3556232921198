/* ==========================================================================
   #COLOR-VARIABLES
   ========================================================================== */

:root {
	--color-basic-100: #ffffff;
	--color-basic-200: #f7f9fc;
	--color-basic-300: #edf1f7;
	--color-basic-400: #e4e9f2;
	--color-basic-500: #c5cee0;
	--color-basic-600: #8f9bb3;
	--color-basic-700: #2e3a59;
	--color-basic-800: #222b45;
	--color-basic-900: #192038;
	--color-basic-1000: #151a30;
	--color-basic-1100: #101426;
	--color-basic-1200: #cdced9;

	--color-basic-transparent-100: rgba(143, 155, 179, 0.08);
	--color-basic-transparent-200: rgba(143, 155, 179, 0.16);
	--color-basic-transparent-300: rgba(143, 155, 179, 0.24);
	--color-basic-transparent-400: rgba(143, 155, 179, 0.32);
	--color-basic-transparent-500: rgba(143, 155, 179, 0.4);
	--color-basic-transparent-600: rgba(143, 155, 179, 0.48);

	--color-danger-100: #fff2f2;
	--color-danger-200: #ffd6d9;
	--color-danger-300: #ffa8b4;
	--color-danger-400: #ff708d;
	--color-danger-500: #ff3d71;
	--color-danger-600: #db2c66;
	--color-danger-700: #b81d5b;
	--color-danger-800: #94124e;
	--color-danger-900: #700940;
	--color-danger-1000: #ff9f9f;

	--color-danger-transparent-100: rgba(255, 61, 113, 0.08);
	--color-danger-transparent-200: rgba(255, 61, 113, 0.16);
	--color-danger-transparent-300: rgba(255, 61, 113, 0.24);
	--color-danger-transparent-400: rgba(255, 61, 113, 0.32);
	--color-danger-transparent-500: rgba(255, 61, 113, 0.4);
	--color-danger-transparent-600: rgba(255, 61, 113, 0.48);

	--color-warning-100: #fffdf2;
	--color-warning-200: #fff1c2;
	--color-warning-300: #ffe59e;
	--color-warning-400: #ffc94d;
	--color-warning-500: #ffaa00;
	--color-warning-600: #db8b00;
	--color-warning-700: #b86e00;
	--color-warning-800: #945400;
	--color-warning-900: #703c00;

	--color-warning-transparent-100: rgba(255, 170, 0, 0.08);
	--color-warning-transparent-200: rgba(255, 170, 0, 0.16);
	--color-warning-transparent-300: rgba(255, 170, 0, 0.24);
	--color-warning-transparent-400: rgba(255, 170, 0, 0.32);
	--color-warning-transparent-500: rgba(255, 170, 0, 0.4);
	--color-warning-transparent-600: rgba(255, 170, 0, 0.48);

	--color-success-100: #f0fff5;
	--color-success-200: #ccfce3;
	--color-success-300: #8cfac7;
	--color-success-400: #2ce69b;
	--color-success-500: #00d68f;
	--color-success-600: #00b887;
	--color-success-700: #00997a;
	--color-success-800: #007d6c;
	--color-success-900: #004a45;
	--color-success-1000: #3fa075;

	--color-bank-accounts: #a3b7ff;
	--color-card-accounts: #f3acff;
	--color-bank-accounts-light: #e8edff;

	--color-grey: #eaeaea;

	--color-success-transparent-100: rgba(0, 214, 143, 0.08);
	--color-success-transparent-200: rgba(0, 214, 143, 0.16);
	--color-success-transparent-300: rgba(0, 214, 143, 0.24);
	--color-success-transparent-400: rgba(0, 214, 143, 0.32);
	--color-success-transparent-500: rgba(0, 214, 143, 0.4);
	--color-success-transparent-600: rgba(0, 214, 143, 0.48);

	--color-primary-100: #f2f6ff;
	--color-primary-200: #d9e4ff;
	--color-primary-300: #a6c1ff;
	--color-primary-400: #598bff;
	--color-primary-500: #3366ff;
	--color-primary-600: #274bdb;
	--color-primary-700: #1a34b8;
	--color-primary-800: #102694;
	--color-primary-900: #091c7a;
	--color-primary-1000: #2e62ff;
	--color-primary-1100: #dbe3fa;

	--color-primary-soft-100: #e1e9ff;

	--color-primary-transparent-100: rgba(51, 102, 255, 0.08);
	--color-primary-transparent-200: rgba(51, 102, 255, 0.16);
	--color-primary-transparent-300: rgba(51, 102, 255, 0.24);
	--color-primary-transparent-400: rgba(51, 102, 255, 0.32);
	--color-primary-transparent-500: rgba(51, 102, 255, 0.4);
	--color-primary-transparent-600: rgba(51, 102, 255, 0.48);

	--text-basic-color: var(--color-basic-800);
	--text-alternate-color: var(--color-basic-100);
	--text-control-color: var(--color-basic-100);
	--text-disabled-color: var(--color-basic-transparent-600);
	--text-hint-color: var(--color-basic-600);
	--text-standby-color: #98f3e1;
	--text-danger-color: #67000a;

	--color-stanby: #98f3e1;
	--color-stanby-transparent: rgba(152, 243, 225, 0.3);
	--color-mat-basic: rgba(0, 0, 0, 0.87);
	--color-mat-primary: var(--color-primary-1000);
	--color-mat-accent: #ff4081;
	--color-mat-warn: #f44336;
	//--color-mat-disabled: rgba(0, 0, 0, 0.26);

	--color-expansion-panel-header: var(--text-standby-color);
	--color-expansion-panel-header-hover: #92ebd9;
	--color-expansion-panel-header-secondary-border: #c4c4c4;

	--color-black: #000000;
	--color-black-100: #202a35;
	--color-white: #ffffff;
	--color-off-white: #f1f2f5;
	--color-blue: #0054b0;

	--color-disabled: #8992a3;
	--color-disabled-secondary: #767676;
	--color-disabled-tertiary: 168, 168, 168;

	--color-text-default: #17242e;
	--color-page-title-primary: var(--color-primary-1000);
	--color-page-title-secondary: var(--color-black-100);

	--color-title-item: var(--color-black-100);
	--color-label-input: var(--color-disabled);

	--color-disabled-btn: var(--color-disabled);
	--color-enabled-btn-primary: var(--color-black-100);
	--color-enabled-btn-secondary: var(--color-disabled-secondary);

	--color-border-payouts: #cbcbcb;

	--color-side-nav-background: #0071eb;
	--color-side-nav-right-border: #7fb8f5;
	--color-side-nav-subcat-active-bg: #3f94f0;

	//page

	--main-page-background-color: #f2f2f2;

	//pagination
	--pagination-border-color: #c3c5ca;

	// Side menu
	--side-menu-drawer-background: var(--color-side-nav-background);
	--side-menu-item-activated-background: #0054b0;
	--side-menu-main-separator-color: #ffffff;
	--side-menu-secondary-separator-color: #bfdbfa;
	--side-menu-item-text: #ffffff;
	--side-menu-opened-width: 265px;
	--side-menu-closed-width: 130px;
	--side-menu-button-width: 37px;
	--side-menu-button-opened-width: calc(var(--side-menu-opened-width) - 21px);
	--side-menu-button-closed-width: calc(var(--side-menu-closed-width) - 21px);
	--color-side-menu-divider: #3f94f0;
	--color-profile-menu-text: #2d3841;
	--color-profile-menu-version: #a1a1a1;
	--color-profile-menu-avatar-color: #b1caff;

	// Stepper
	--color-item-current: var(--color-primary-1000);
	--color-item-current-contrast: #ffffff;
	--color-item-previous: var(--color-disabled-secondary);
	--color-item-previous-contrast: #ffffff;
	--color-item-next: var(--color-disabled-secondary);
	--color-item-next-contrast: #ffffff;
	--color-background: rgba(255, 255, 255, 0.8);

	// View
	--view-padding-horitzontal: 64px;
	--view-padding-vertical: 30px;

	// Modal
	--modal-color-background: #f3f3f3;
	--modal-padding: var(--view-padding-horitzontal);
	--modal-padding-secondary: var(--spacing-unit-middle-large);

	// Inputs
	--input-error-color: var(--color-danger-500);
	--input-enabled-color: var(--color-text-default);
	--input-enabled-border-color: var(--color-disabled-secondary);
	--input-enabled-border-color-active: #545454;
	--input-enabled-background-color: #fcfcfc;
	--input-disabled-color: var(--color-disabled);
	--input-disabled-border-color: var(--color-disabled);
	--input-disabled-background-color: #ededed;
	--input-label-color: var(--color-disabled);
	--input-checkbox-radio-label-color: var(--color-text-default);
	--input-checkbox-radio-width: 25px;
	--input-checkbox-radio-backrgound-color-active: var(--color-primary-1000);

	--input-toggle-border: #80acff;
	--input-toggle-background: #b3cdff;

	// No items layout
	--no-items-poker-face-color: #2c3e50;
	--no-items-poker-face-size: 100px;
	--no-items-poker-face-width: 5px;

	// Header container colour
	--color-header-background: #ebebeb;
	--color-dialog-header-background: #2e62ff;
	--color-background-disabled: #e7e7ed;
	--color-header-blue-background: #b3cdff;
	--color-table-background: #f9f9f9;
	--color-background-disabled-light: #e7e7ed;

	// Accounts
	--account-separator-color: var(--color-disabled);

	// Tooltip
	--tooltip-background-color: #172b4d;

	// PayGroups List Hover
	--color-background-blue: #e1e8ff;

	// Payment calendar variable
	--color-background-hover: #ececec;
	--color-funding: #fa49a9;
	--color-nets: var(--color-primary-1000);
	--color-g2n: #eeba17;

	--color-borders: #979797;

	//CSA cs admin
	--background-role-csa: #cfd186;
	--color-role-csa: #596157;
	//EMP employee
	--background-role-emp: #e1dee9;
	--color-role-emp: #a09abc;
	//IMPC ts st implementation consultant
	--background-role-impc: #8190ca;
	--color-role-impc: #171738;
	//SA super admin
	--background-role-sa: #93c4e0;
	--color-role-sa: #485c6c;
	//TA tresury admin
	--background-role-ta: #a3b7ff;
	--color-role-ta: #004ad7;
	//TIC ts implementation consutlant
	--background-role-tic: #ffca99;
	--color-role-tic: #b95000;
	//TOM ts operations manqger
	--background-role-tom: #ffeeb8;
	--color-role-tom: #ce9400;
	//TOC tresury operations consultant
	--background-role-toc: #f3acff;
	--color-role-toc: #b95000;
	//TITL ts itl imlmentation lead
	--background-role-titl: #d3d0cb;
	--color-role-titl: #767676;
	//TOL ts otl operations lead
	--background-role-tol: #ebbab9;
	--color-role-tol: #b95000;

	// Client role
	--background-role-client: #80c687;
	--color-role-client: #485c6c;
	// TS_OCB ts operation consultant booker
	--background-role-tsocb: #a1c7a9;
	--color-role-tsocb: #323e34;

	// TS_VO ts view only
	--background-role-tsvo: #d9f2f8;
	--color-role-tsvo: #2a439c;

	//Active user
	--background-rol-active: #4566d8;

	//Paygroups inactive text color
	--inactive-text-red: #ed4c5c;

	//Service Provider Error
	--error-default: #da1414;

	//Payouts

	--color-thumbnail-100: #ffd76e;
	--color-thumbnail-200: #80c687;
	--color-thumbnail-300: #ffca99;
	--color-cancelled-booking-background: #f7c59e;

	//status color event details
	--complete-background-color: #c9ffe7;
	--complete-color: #287d3c;

	--inProgress-background-color: #d1eaff;
	--inProgress-color: #6ea2e2;

	--pending-background-color: #ffe9d6;
	--pending-color: #ff8f39;
	--errors-background-color: #ffd2d7;
	--errors-color: #da1414;

	--color-status-booked: #539ac2;

	// Calendar Cycle Menu
	--color-menu-hover-background: #f2f2f2;

	//Pay-elective
	--color-upload-button-dark-grey: #272833;
	--color-filter-button: #292d32;
	--color-no-PayMethod-border: #ff8412;

	//Search - dialogs
	--color-search-icon: #231f20;
	--color-border-search: #cecece;

	--color-reset-grey: #a7a9bc;

	//breadcrumb
	--box-shadow: #272833;
	--search-icon: #272833;
	--drop-down-hover: #efefef;
	--arrow-selected: #0b5fff;

	//matTooltip
	--mat-tool-tip-background: #231f20;

	//TPPGroups

	--color-group-1-background: #00c3cf63;
	--color-group-1-text: #00757c;

	--color-group-2-background: #ffcc1763;
	--color-group-2-text: #b48800;

	--color-group-3-background: #a57aff63;
	--color-group-3-text: #9a5cff;

	--color-group-4-background: #ffe3c9;
	--color-group-4-text: #ff8412;
	//Milestones and Groups
	--color-milestone-container-background: #e1e1e1;

	--color-group-NET-bg: #2e62ff63;
	--color-group-TTP1-bg: #00c3cf63;
	--color-group-TTP2-bg: #ffcc1763;
	--color-group-TTP3-bg: #a57aff63;
	--color-group-TTP4-bg: #ffe3c9;

	--color-group-NET-text: #2e62ff;
	--color-group-TTP1-text: #00757c;
	--color-group-TTP2-text: #b48800;
	--color-group-TTP3-text: #9a5cff;
	--color-group-TTP4-text: #ff8412;

	//TPP input data
	--color-service-details-background: #f7f8f9;
	--color-service-hover: #f0f5ff;

	--color-divider: #c1c1c1;
	--role-blue-background: #becdff;
	--role-blue-text: #1b449c;

	//Global dashboard
	--color-progress-bar-background-pink: #ffb0b0;
	--color-progress-bar-background-orange: #fce9d8;

	--color-progress-bar-filled-background-pink: #e14343;
	--color-progress-bar-filled-background-orange: #ff8412;

	--color-missing-cards-icon-conatiner: #ef80ff;
	--color-hyperlink: #406ffc;
	--color-button-text: #272833;
}
