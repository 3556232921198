/* ==========================================================================
   #INPUT
   ========================================================================== */

// General
//All input will have elipses
input {
	text-overflow: ellipsis;
	overflow: hidden;
}

input:not(.mat-input-element),
select {
	background: var(--input-enabled-background-color);
	border-radius: 4px;
	border-style: solid;
	border-width: 1px;
	border-color: var(--input-enabled-border-color);
	padding: var(--spacing-unit-small) var(--spacing-unit);
	width: 100%;
	height: 48px;

	font-style: normal;
	font-weight: normal;
	font-size: var(--font-size-medium);
	line-height: 19px;
	color: var(--input-enabled-color);

	// Ellipsis
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;

	&.ng-touched:not(form),
	&.ng-dirty:not(form) {
		&.ng-invalid:not(form) {
			border: 1px solid var(--input-error-color) !important;
		}
	}

	&:disabled {
		background-color: var(--input-disabled-background-color);
		border-color: var(--input-disabled-border-color);
		color: var(--input-disabled-color);
		cursor: not-allowed;

		&::placeholder {
			color: var(--input-disabled-color);
		}
	}

	&:focus {
		outline: none;
	}
}

// Radio
input[type="radio"] {
	width: auto;
}

// File
input[type="file"] {
	display: none;
}

// Checkbox & Radio
input[type="checkbox"],
input[type="radio"] {
	width: 25px;
	height: 25px;
	border: 1px solid var(--input-enabled-border-color);
	border-radius: 3px;

	// For <input id="id" type="checkbox"><label id="id">Label</label>
	& + label {
		font-size: var(--font-size-medium);
		line-height: 19px;
		color: var(--input-checkbox-radio-label-color);
		margin-left: var(--spacing-unit-small);

		&:not(:last-child) {
			margin-right: var(--spacing-unit-huge);
		}
	}
}

// For <label><input type="checkbox">Label</label>
label {
	display: flex;
	align-items: center;
	font-size: var(--font-size-medium);
	line-height: 19px;
	color: var(--input-checkbox-radio-label-color);

	input[type="checkbox"],
	input[type="radio"] {
		margin-right: var(--spacing-unit-small);
	}

	&:not(:last-child) {
		margin-right: var(--spacing-unit-huge);
	}
}

// Hide up & down arrow buttons on type number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

// To firefox
input[type="number"] {
	-moz-appearance: textfield;
}

.label-input {
	color: var(--input-label-color);
	font-style: normal;
	font-weight: bold;
	font-size: var(--font-size-medium);
	line-height: 19px;
	text-transform: uppercase;
	margin-bottom: var(--spacing-unit);
}

.first-input-row {
	padding-left: 0 !important;
}

.last-input-row {
	padding-right: 0 !important;
}
