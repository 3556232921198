/* ==========================================================================
   #ANGULAR MATERIALS: STEPPER
   ========================================================================== */

mat-stepper {
	background-color: transparent !important;

	.mat-horizontal-stepper-header-container {
		// Disable click navigation in header
		pointer-events: none;

		margin-bottom: 48px;
		background: var(--color-background);
		border-radius: 8px;
		height: 104px;

		mat-step-header {
			padding: 0px 48px;
			background-color: transparent !important;

			.mat-step-label {
				// State next
				font-size: 20px;
				line-height: 34px;
				color: var(--color-item-next);

				&.mat-step-label-active {
					// State completed
					color: var(--color-item-previous);
					font-weight: 600;

					&.mat-step-label-selected {
						// State current
						color: var(--color-item-current);
					}
				}

				@media (max-width: 1880px) {
					display: none;

					&.mat-step-label-active {
						&.mat-step-label-selected {
							// State current
							display: block;
							color: var(--color-item-current);
						}
					}
				}
			}

			.mat-step-icon {
				margin-right: 16px;
				font-size: 20px;
				line-height: 20px;
				height: 36px;
				width: 36px;
				font-weight: 600;
				display: flex;
				justify-content: center;
				align-items: center;

				&.mat-step-icon-state-number {
					// State next
					background: transparent;
					border: 1px solid var(--color-item-next);
					color: var(--color-item-next);

					&.mat-step-icon-selected {
						// State current
						background: var(--color-item-current);
						border: unset;
						color: var(--color-item-current-contrast);
					}
				}

				&.mat-step-icon-state-edit {
					// State completed
					background: var(--color-item-previous);
				}

				.mat-step-icon-content,
				.mat-icon {
					position: relative;
					top: unset;
					left: unset;
					transform: unset;
				}

				.mat-icon {
					display: flex;
					justify-content: center;
					align-items: center;
					font-weight: bold;
					font-size: 21px;
					height: unset;
					width: unset;
				}
			}
		}

		.mat-stepper-horizontal-line {
			border-top-color: var(--color-item-next);
		}
	}
}
