.paygroup-items-container {
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0px 4px 4px rgba(129, 129, 129, 0.25);
}

.paygroup-items-header {
	padding: 25px 40px;
	background-color: var(--text-standby-color);

	h3 {
		line-height: var(--font-size-huge);
		font-size: var(--font-size-huge);
		font-weight: 600;
	}
}

/*.paygroup-items-header {
	padding: 19px 48px;
	background-color: var(--color-expansion-panel-header);
	h3 {
		font-size: 25px;
		font-weight: 600;
		line-height: 31px;
	}
}*/

.paygroup-container {
	flex: 1;
}

.paygroup-container:hover {
	background-color: var(--color-expansion-panel-header);
}

.paygroup-list-item:hover {
	background-color: var(--color-background-blue);
}

.paygroup-list-item {
	cursor: pointer;
	padding: 32px;
}

.paygroup-mat-divider {
	width: 100%;
}

.paygroup-item-main-heading {
	font-weight: 600;
	margin: 4px 0px;
	color: var(--color-title-item);
}

.paygroup-item-main-subheading {
	font-weight: 400;
	margin: 4px 0px;
	color: var(--color-title-item);
}
