/* ==========================================================================
   #TEXT
   ========================================================================== */

.c-text-title {
	font-style: normal;
	font-weight: 700;
	font-size: var(--font-size-huge);
	line-height: 29px;
	color: var(--color-page-title-primary);
}

.c-text-navigation-title {
	font-style: normal;
	font-weight: 400;
	font-size: var(--font-size-medium);
	line-height: 29px;
	color: var(--color-page-title-primary);
}

.c-text-navigation-title:hover {
	cursor: pointer;
}

.c-text-sub-title {
	font-style: normal;
	font-weight: bold;
	font-size: var(--font-size-middle-huge);
	line-height: 23px;
	color: var(--color-basic-600);
}

.c-text-title-page {
	margin-bottom: var(--spacing-unit-large);
}

.c-text-title-secondary-page {
	color: var(--color-page-title-secondary);
	font-style: normal;
	font-size: var(--font-size-middle-huge);
	line-height: 16px;
	margin-bottom: 2.5em;
	margin-top: 5em;
}

.c-text-label {
	color: var(--color-label-input);
	font-weight: bold;
	font-size: var(--font-size-medium);
	line-height: 16px;
	text-transform: uppercase;
	margin-bottom: 10px;
}

.c-title-item {
	font-size: var(--font-size-middle-huge);
	line-height: 29px;
	color: var(--color-title-item);
}
