/* ==========================================================================
   #ANGULAR MATERIALS: EXPANDED PANEL
   ========================================================================== */

mat-accordion {
	& + mat-accordion {
		display: block;
		margin-top: var(--spacing-unit-large);
	}
}

mat-expansion-panel {
	width: 100%;

	& + mat-expansion-panel {
		margin-top: var(--spacing-unit) !important;
		border-radius: 4px !important;
	}
}

mat-expansion-panel-header {
	padding: var(--spacing-unit) var(--spacing-unit-large) !important;
	background-color: var(--color-basic-100);

	&.mat-expanded {
		background-color: var(--color-expansion-panel-header);

		&:focus,
		&:hover {
			background: var(--color-expansion-panel-header-hover) !important;
		}
	}

	.mat-expansion-indicator:after {
		color: var(--color-basic-900);
	}
}

mat-expansion-panel-header-title {
	justify-content: space-between;
}

//Expansion panel secondary

mat-accordion {
	&.basic {
		mat-expansion-panel-header {
			padding: var(--spacing-unit-small) var(--spacing-unit-large) !important;
			background-color: var(--color-basic-200);
			border: 1px solid var(--color-expansion-panel-header-secondary-border);

			&.mat-expanded {
				background-color: var(--color-basic-100);
				border: none;

				&:focus,
				&:hover {
					background: var(--color-basic-100) !important;
				}
			}

			.mat-expansion-indicator:after {
				color: var(--color-primary-700);
			}
		}

		mat-expansion-panel-header .information-title {
			font-size: var(--font-size-middle-huge);
			color: var(--color-primary-700);
		}
	}
}
