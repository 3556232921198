@import "../tools/functions.tools";

$breakpoints: (
	xs: 0,
	// 0 - 480px is xs, etc..
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px
);

// Breakpoints that won't get interupted by standard sizes.
$custom-breakpoints: (
	laptop-or-less: 1880px
);

@include _assert-ascending($breakpoints, "$breakpoints");
@include _assert-starts-at-zero($breakpoints, "$breakpoints");

@mixin xs {
	@media (min-width: map-get($breakpoints, "xs")) {
		@content;
	}
}

@mixin sm {
	@media (min-width: map-get($breakpoints, "sm")) {
		@content;
	}
}

@mixin md {
	@media (min-width: map-get($breakpoints, "md")) {
		@content;
	}
}

@mixin lg {
	// Laptop screen
	@media (min-width: map-get($breakpoints, "lg")) {
		@content;
	}
}

@mixin xl {
	@media (min-width: map-get($breakpoints, "xl")) {
		@content;
	}
}

@mixin xxl {
	@media (min-width: map-get($breakpoints, "xxl")) {
		@content;
	}
}

@mixin laptop-or-less {
	@media (min-width: map-get($breakpoints, "xxl")) and (max-width: map-get($custom-breakpoints, "laptop-or-less")) {
		@content;
	}
}

@mixin large-desktop {
	@media (min-width: map-get($custom-breakpoints, "laptop-or-less")) {
		@content;
	}
}
