/* ==========================================================================
   #ANGULAR MATERIALS: BUTTON
   ========================================================================== */

.mat-button {
	mat-icon ~ * {
		margin-left: var(--spacing-unit-small);
	}

	&.c-button-dashed {
		border-width: 1px;
		border-style: dashed;
		border-color: var(--color-mat-basic);

		&.mat-primary {
			border-color: var(--color-mat-primary);
			color: var(--color-mat-primary);
		}

		&.mat-accent {
			border-color: var(--color-mat-accent);
		}

		&.mat-warn {
			border-color: var(--color-mat-warn);
		}

		&.mat-dark {
			border-color: var(--color-basic-100);
		}

		&.mat-blue-sky {
			border-color: var(--color-primary-1000);
			color: var(--color-primary-1000);
		}

		// Always at the end
		&.mat-button-disabled {
			border-color: var(--color-mat-disabled);
			color: var(--color-mat-disabled);
		}
	}

	&.c-button-fill {
		width: 100%;
	}

	&.c-button-small {
		padding: var(--spacing-unit-tiny) var(--spacing-unit-small) !important;
	}

	&:not(:last-child) {
		margin-right: var(--spacing-unit);
	}
}

.mat-button-base {
	font-size: var(--font-size-medium);
	line-height: 16px;
	//padding: var(--spacing-unit-tiny) var(--spacing-unit-huge) !important;
	text-transform: uppercase;

	&.c-button-fill {
		width: 100%;
	}

	&.c-button-small {
		padding: var(--spacing-unit-tiny) var(--spacing-unit-small) !important;
	}

	&:not(:last-child) {
		margin-right: var(--spacing-unit);
	}
}

// Set configuration to button white with border black
.mat-flat-button {
	border: 1px solid var(--color-basic-900) !important;

	&.mat-primary,
	&.mat-accent,
	&.mat-warn,
	&.mat-dark {
		border: unset !important;
	}

	&.mat-button-disabled {
		border: 1px solid var(--color-mat-disabled) !important;

		&.mat-primary,
		&.mat-accent,
		&.mat-warn,
		&.mat-dark {
			border: unset !important;
		}
	}
}

// Set a new color type: dark --> color="dark" working
.mat-dark {
	background-color: var(--color-basic-900);
	color: var(--color-basic-100);

	&.mat-button-disabled {
		background-color: var(--color-basic-600);
		border: unset !important;
	}
}

.mat-blue {
	background-color: var(--color-primary-1000);
	color: var(--color-basic-100);

	&.mat-button-disabled {
		background-color: var(--color-basic-500);
		color: var(--color-basic-100);
		border: unset !important;
	}
}
