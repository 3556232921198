@import "./breakpoints.settings.scss";

/* ==========================================================================
   #FONT-SIZES-VARIABLES
   ========================================================================== */

// @see: https://www.cdnfonts.com/general-sans.font
@import url("https://fonts.cdnfonts.com/css/general-sans");

// @see: https://www.cdnfonts.com/pt-serif.font
@import url("https://fonts.cdnfonts.com/css/pt-serif");

// @see: https://www.cdnfonts.com/saira.font
@import url("https://fonts.cdnfonts.com/css/saira");

$font-family: "General Sans";
$color-font-primary: #202a35; // (Figma: DarkWez)
$color-font-secondary: #8992a3; // (Figma: Main/Disabled)

:root {
	/*
	* @depreciated variables use wpay-fonts instead
	*/

	font-family: $font-family;

	--font-size-tiny: 12px;
	--font-size-semi-small: 13px;
	--font-size-small: 14px;
	--font-size-medium: 15px;
	--font-size-regular: 16px;
	--font-size-large: 17px;
	--font-size-xlarge: 18px;
	--font-size-middle-huge: 20px;
	--font-size-huge: 25px;
	--font-size-super-huge: 32px;
	--font-size-extremely-huge: 36px;

	// Apply the default font family to all elements.
	font-family: $font-family;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

//Rules:
// The initial setup is for anything from '<= 1880px'
// the @Include should be for larger desktops '< 1880px'

.xs-header {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	font-size: 25px;
	color: $color-font-primary;

	@include large-desktop {
		font-size: 30px;
	}
}

.xs-header-breadcrumb {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	font-size: 26px;
	line-height: 14px;
	position: relative;
	top: -4px;

	@include large-desktop {
		font-size: 30px;
		line-height: 38px;
	}
}

.xl-header-breadcrumb {
	font-family: General Sans;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	@include large-desktop {
		font-size: 25px;
		line-height: 38px;
	}
}

.xs-title {
	font-family: $font-family;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	color: $color-font-primary;

	@include large-desktop {
		font-size: 20px;
		line-height: 26px;
	}
}

.xs-title-bold {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	font-size: 18px !important;
	line-height: 26px;
	color: $color-font-primary;

	@include large-desktop {
		font-size: 20px !important;
		line-height: 26px;
	}
}

.section-name {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	font-size: 26px !important;
	line-height: 35px;

	@include large-desktop {
		font-size: 30px !important;
		line-height: 40px;
	}
}

.xs-title-2-bold {
	font-family: $font-family;
	font-size: 14px !important;
	font-weight: 600;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;

	@include large-desktop {
		font-size: 16px !important;
		line-height: 21px;
	}
}

.xs-title-2-medium {
	font-family: $font-family;
	font-size: 12px !important;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: left;

	@include large-desktop {
		font-size: 14px !important;
		line-height: 19px;
	}
}

.xs-company-header {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	text-transform: uppercase;
	color: $color-font-primary;

	@include large-desktop {
		font-size: 22px;
	}
}

.body-normal {
	font-family: $font-family;
	font-style: normal !important;
	font-weight: 400;
	font-size: 16px;
	line-height: 25px;
	color: $color-font-primary;

	@include large-desktop {
		font-size: 17px;
		line-height: 25px;
	}
}

.table-header-item {
	font-family: $font-family;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	color: $color-font-secondary;
	line-height: normal;

	@include large-desktop {
		font-size: 16px;
	}
}

.table-row-item {
	font-family: $font-family;
	font-size: 16px;
	line-height: 25px;
	font-weight: 400;
	color: color-font-primary;

	@include large-desktop {
		font-size: 17px;
	}
}

.table-row-item-disabled {
	font-family: $font-family;
	font-size: 16px;
	line-height: 25px;
	font-weight: 400;
	color: var(--color-disabled);

	@include large-desktop {
		font-size: 17px;
	}
}

.body-bold {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
	color: $color-font-primary;

	@include large-desktop {
		font-size: 17px;
		line-height: 16px;
	}
}

.body-medium {
	font-family: $font-family;
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 16px;
	color: $color-font-primary;

	@include large-desktop {
		font-size: 18px;
	}
}

.body-italic-bold {
	font-family: $font-family;
	font-style: italic;
	font-weight: 600;
	font-size: 16px;
	line-height: 16px;
	color: $color-font-primary;

	@include large-desktop {
		font-size: 17px;
		line-height: 16px;
	}
}

.body-italic {
	font-family: $font-family;
	font-size: 16px !important;
	line-height: 16px;
	font-style: italic;
	font-weight: 400;
	letter-spacing: 0em;
	text-align: left;

	@include large-desktop {
		font-size: 17px !important;
		line-height: 16px;
	}
}

.body-normal-semibold {
	font-family: $font-family;
	font-size: 15px !important;
	line-height: 15px;
	font-weight: 500;
	letter-spacing: 0em;

	@include large-desktop {
		font-size: 16px !important;
		line-height: 16px;
	}
}

.body-semibold {
	font-family: $font-family;
	font-size: 16px !important;
	line-height: 16px;
	font-weight: 600;
	letter-spacing: 0em;

	@include large-desktop {
		font-size: 17px !important;
		line-height: 16px;
	}
}

.body-small-normal {
	font-family: $font-family;
	font-style: normal !important;
	font-weight: 400;
	font-size: 14px !important;
	line-height: 25px;
	color: $color-font-primary;

	@include large-desktop {
		font-size: 15px !important;
		line-height: 25px;
	}
}

.body-small-bold {
	font-family: $font-family;
	font-style: normal !important;
	font-weight: 600;
	font-size: 14px !important;
	line-height: 16px;
	color: $color-font-primary;

	@include large-desktop {
		font-size: 15px !important;
		line-height: 16px;
	}
}

.body-small-italic {
	font-family: $font-family;
	font-style: normal !important;
	font-weight: 400;
	font-size: 14px !important;
	line-height: 16px;
	color: $color-font-primary;

	@include large-desktop {
		font-size: 15px !important;
		line-height: 16px;
	}
}

.body-small-italic-bold {
	font-family: $font-family;
	font-style: italic !important;
	font-weight: 600;
	font-size: 14px !important;
	line-height: 16px;
	color: $color-font-primary;

	@include large-desktop {
		font-size: 15px !important;
		line-height: 16px;
	}
}

.body-xsmall-normal {
	font-family: $font-family;
	font-style: normal !important;
	font-weight: 400;
	font-size: 12px !important;
	color: $color-font-primary;

	@include large-desktop {
		font-size: 13px !important;
	}
}

.body-xsmall-bold {
	font-family: $font-family;
	font-style: normal !important;
	font-weight: 600;
	font-size: 12px !important;
	line-height: 16px;
	color: $color-font-primary;

	@include large-desktop {
		font-size: 13px !important;
	}
}

.body-xsmall-italic {
	font-family: $font-family;
	font-style: normal !important;
	font-weight: 400;
	font-size: 12px !important;
	line-height: 16px;
	color: $color-font-primary;

	@include large-desktop {
		font-size: 13px !important;
	}
}

.body-xsmall-italic-bold {
	font-family: $font-family;
	font-style: normal !important;
	font-weight: 400;
	font-size: 12px !important;
	line-height: 16px;
	color: $color-font-primary;

	@include large-desktop {
		font-size: 13px !important;
	}
}

.xs-category-titles {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	font-size: 13px !important;
	text-transform: uppercase;
	color: $color-font-secondary;

	@include large-desktop {
		font-size: 15px !important;
	}
}

.xs-placeholder-label {
	font-family: $font-family;
	font-style: normal;
	font-weight: 600;
	font-size: 11px !important;
	color: $color-font-secondary;

	@include large-desktop {
		font-size: 12px !important;
	}
}

.xl-title-normal {
	font-family: $font-family;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px; /* 144.444% */

	@include large-desktop {
		font-size: 23px !important;
		line-height: 26px;
	}
}

.xs-body-xsmall-semibold {
	color: var(--White, #fff);
	text-align: center;
	/* XL Body xsmall semibold */
	font-family: $font-family;
	font-size: 13px;
	font-style: normal;
	font-weight: 600 !important;
	line-height: 16px; /* 123.077% */

	@include large-desktop {
		font-size: 15px !important;
	}
}

body {
	font-family: $font-family;
}

.xl-section-name {
	font-family: $font-family;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
	color: var(--color-white);
}

.history-log-item {
	font-family: $font-family;
	font-size: 17px;
	font-style: normal;
	font-weight: 500;
	line-height: 22px;
}

.history-log-time {
	font-family: $font-family;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
}

.history-log-status {
	font-family: $font-family;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px; /* 183.333% */
	text-transform: uppercase;
}
