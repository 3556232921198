/* ==========================================================================
   #DIVIDER
   ========================================================================== */

.display-value {
	position: relative;
	background: var(--color-basic-200);
	border: 1px solid var(--color-basic-600);
	box-sizing: border-box;
	border-radius: 5px;
	display: flex;
	align-items: center;
	padding: 0 15px;
	height: 48px;

	mat-icon {
		color: var(--color-basic-600);
		margin-right: 10px;

		&:last-of-type {
			margin-left: 10px;
		}

		&:first-of-type {
			margin-left: 0px;
		}
	}
}
