/* ==========================================================================
   #ANGULAR MATERIALS: Checkbox
   ========================================================================== */

mat-checkbox:not(:last-child) {
	margin-right: 24px;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
	background: var(--color-mat-primary) !important;
}

mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
	background-color: var(--color-mat-primary) !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
	background-color: var(--color-mat-primary) !important;
}

.mat-checkbox-disabled {
	cursor: not-allowed !important;
	.mat-checkbox-inner-container {
		.mat-checkbox-frame {
			border: unset !important;
		}

		.mat-checkbox-background {
			background-color: var(--input-disabled-background-color);
			border-radius: 4px;
			padding: 1px;
			flex-shrink: 0;
		}
	}
}
