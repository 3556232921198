.toggle-default {
	.mat-slide-toggle-content {
		white-space: unset;
		overflow: unset;
		text-overflow: unset;
		font-size: var(--font-size-small) !important;

		@include large-desktop {
			font-size: var(--font-size-medium) !important;
		}
	}

	.mat-slide-toggle-thumb {
		width: 22px !important;
		height: 22px !important;
		transform: translate(10%, 15%);
		margin-top: 10%;
	}

	.mat-slide-toggle-bar {
		border-radius: 12px !important;
		height: 26px !important;
		width: 38px !important;
	}
}

.toggle-checked {
	.mat-slide-toggle-thumb {
		width: 22px !important;
		height: 22px !important;
		transform: translate(-15%, 0%) !important;
		margin-top: 20% !important;
		background-color: white !important;
	}

	.mat-slide-toggle-bar {
		border-radius: 12px !important;
		height: 26px !important;
		width: 38px !important;
		border-style: solid !important;
		border-width: 1px !important;
		border-color: var(--color-primary-1000) !important;
		background-color: var(--color-primary-1100) !important;
	}

	&.mat-slide-toggle.mat-disabled {
		opacity: 1 !important;
	}
}

.toggle-checked-alternative {
	.mat-slide-toggle-thumb {
		width: 22px !important;
		height: 22px !important;
		transform: translate(-15%, 0%) !important;
		margin-top: 20% !important;
		background-color: white !important;
	}

	.mat-slide-toggle-bar {
		border-radius: 12px !important;
		height: 26px !important;
		width: 38px !important;
		border-style: solid !important;
		border-width: 1px !important;
		border-color: var(--color-role-ta) !important;
		background-color: var(--color-primary-1000) !important;
	}

	&.mat-slide-toggle.mat-disabled {
		opacity: 1 !important;
	}
}
