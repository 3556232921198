/* ==========================================================================
   #GRID
   ========================================================================== */

.o-grid-5 {
	display: flex;
	flex-wrap: wrap;

	> * {
		flex: 1 0 17%;
		padding: var(--spacing-unit);
		max-width: 20%;
	}
}

.o-grid-4 {
	display: flex;
	flex-wrap: wrap;

	> * {
		flex: 1 0 21%;
		padding: var(--spacing-unit);
		max-width: 25%;

		&.offset-3 {
			margin-left: 75%;
		}

		&.offset-2 {
			margin-left: 50%;
		}

		&.offset-1 {
			margin-left: 25%;
		}
	}
}

.o-grid-3 {
	display: flex;
	flex-wrap: wrap;

	> * {
		flex: 1 0 29.33333%;
		padding: var(--spacing-unit);
		max-width: 33.33333%;

		&.offset-2 {
			margin-left: 66.66666%;
		}

		&.offset-1 {
			margin-left: 33.33333%;
		}
	}

	&.full-width {
		> * {
			flex: 1 0 29.33333%;
			padding: var(--spacing-unit-middle-large) calc(var(--spacing-unit-huge) * 2 / 3)
				var(--spacing-unit-middle-large) 0px;
			max-width: 33.33333%;

			&.offset-2 {
				margin-left: 66.66666%;
			}

			&.offset-1 {
				margin-left: 33.33333%;
			}

			&:nth-child(3n + 2) {
				padding: var(--spacing-unit-middle-large) calc(var(--spacing-unit-huge) / 3)
					var(--spacing-unit-middle-large) calc(var(--spacing-unit-huge) / 3);
			}

			&:nth-child(3n + 3) {
				padding: var(--spacing-unit-middle-large) 0px var(--spacing-unit-middle-large)
					calc(var(--spacing-unit-huge) * 2 / 3);
			}
		}
	}
}

.o-grid-2 {
	display: flex;
	flex-wrap: wrap;

	> * {
		flex: 1 0 46%;
		padding: var(--spacing-unit);
		max-width: 50%;

		&.offset-1 {
			margin-left: 50%;
		}
	}

	&.full-width {
		> * {
			flex: 1 0 46%;
			padding: var(--spacing-unit-middle-large) calc(var(--spacing-unit-huge) / 2)
				var(--spacing-unit-middle-large) 0px;
			max-width: 50%;

			&.offset-1 {
				margin-left: 50%;
			}

			&:nth-child(2n + 2) {
				padding: var(--spacing-unit-middle-large) 0px var(--spacing-unit-middle-large)
					calc(var(--spacing-unit-huge) / 2);
			}
		}
	}
}

.o-grid-2-test {
	display: flex;
	flex-wrap: wrap;

	> * {
		flex: 1 0 29.33333%;
		padding: var(--spacing-unit);
		max-width: 33.33333%;
	}
}
