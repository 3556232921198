/* ==========================================================================
   #ANGULAR MATERIALS: Form Field
   ========================================================================== */

mat-form-field {
	background: var(--input-enabled-background-color) !important;
	border-radius: 4px !important;
	border-style: solid !important;
	border-width: 1px !important;
	border-color: var(--input-enabled-border-color) !important;

	place-content: center;
	justify-content: center;
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	width: 100%;

	&.ng-touched:not(form) {
		&.ng-invalid:not(form) {
			border: 1px solid var(--input-error-color) !important;
			color: var(--input-enabled-border-color);

			/*&.mat-focused {
				border: 1px solid var(--color-danger-600) !important;
			}*/

			/*&:hover {
				border: 1px solid var(--color-danger-500) !important;
			}*/
		}
	}

	&.mat-form-field-disabled {
		background-color: var(--input-disabled-background-color) !important;
		color: var(--input-disabled-color) !important;
		cursor: not-allowed !important;

		.mat-input-element,
		.mat-select-trigger {
			cursor: not-allowed !important;
		}
	}

	&.mat-focused {
		outline: none !important;
		border: none !important;
	}

	& .mat-form-field-outline-end {
		border: none !important;
	}

	& .mat-form-field-outline-start {
		border: none !important;
	}

	& .mat-form-field-outline-gap {
		border: none !important;
	}

	& .mat-form-field-wrapper {
		padding: 0px !important;
		margin: 0px !important;
	}

	& .mat-form-field-flex {
		align-items: center;
		background-color: unset;
	}

	& .mat-form-field-infix {
		border-top: 0.25em solid transparent;
		padding: 0.9em 0 !important;

		.mat-form-field-label-wrapper {
			.mat-form-field-label {
				.mat-form-field-required-marker {
					display: none !important;
				}
			}
		}
	}

	& .mat-form-field-suffix {
		top: 2px !important;
	}

	.mat-form-field-underline {
		display: none;
	}

	.mat-form-field-subscript-wrapper {
		display: none;
	}
}

.mat-input-element::placeholder {
	color: var(--color-role-titl) !important;
	font-size: 16px !important;
}

mat-form-field:hover {
	background: var(--input-enabled-background-color) !important;
	border-radius: 4px !important;
	border-style: solid !important;
	border-width: 1px !important;
	border-color: var(--input-enabled-border-color) !important;
}

mat-form-field:focus {
	background: var(--input-enabled-background-color) !important;
	border-radius: 4px !important;
	border-style: solid !important;
	border-width: 1px !important;
	border-color: var(--input-enabled-border-color) !important;
}

mat-form-field:focus-within {
	background: var(--input-enabled-background-color) !important;
	border-radius: 4px !important;
	border-style: solid !important;
	border-width: 1px !important;
	border-color: var(--input-enabled-border-color) !important;
}
