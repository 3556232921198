.table-header {
	flex: 1;
	background-color: var(--color-header-background);
	color: var(--color-disabled);
	padding: 10px 32px;
}

.table-body {
	flex: 1;
	padding: 20px 32px;
	border-bottom: 0.5px solid var(--color-borders);

	color: var(--color-basic-800);
	font-size: var(--font-size-small);
	line-height: 16px;

	.status-red {
		color: var(--color-danger-500);
	}

	.status-green {
		color: var(--color-success-500);
	}

	.status-created {
		color: var(--color-primary-1000);
	}

	.status-pending {
		color: var(--color-warning-500);
	}

	.status-created {
		color: var(--color-primary-1000);
	}
}

.table-status {
	.status-red {
		color: var(--color-danger-500);
		font-weight: bold;
	}

	.status-green {
		color: var(--color-success-500);
		font-weight: bold;
	}

	.status-created {
		color: var(--color-primary-1000);
		font-weight: bold;
	}

	.status-pending {
		color: var(--color-warning-500);
		font-weight: bold;
	}
}

.table-row-item {
	flex-wrap: nowrap;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
